import '../../../styles/features/Database/pages/Weapons.css'

import { useParams } from 'react-router'

import { useWeaponTableRows } from '../hooks/useWeaponTableRows'

import { fromSlug } from '../../../utils/strings'
import GameItemTable from '../components/GameItemTable'

export default function WeaponsPage() {
  const params = useParams()
  var weaponClassParam = params.weaponClass
  weaponClassParam = weaponClassParam.replace('-', ' ')

  const weaponClass = fromSlug(weaponClassParam)

  const { data, error, loading } = useWeaponTableRows(weaponClass)
  if (loading) return <p>Loading...</p>
  if (error) return <p>Error : {error.message}</p>

  const { edges } = data?.allWeapons || {}

  var row = []

  edges.forEach(function (weaponNode) {
    const weapon = weaponNode.node
    row.push({
      name: weapon.name,
      class: weapon.wClass,
      tier: weapon.tier,
    })
  })

  const TITLE = 'Weapons'
  const HEADERS = ['Name', 'Class', 'Tier']

  return (
    <div id="weapon-items-table" key={weaponClass}>
      <GameItemTable title={TITLE} headers={HEADERS} data={row} />
    </div>
  )
}
