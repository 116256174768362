export function fromSlug(str) {
  const arr = str.split(' ')

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
  }

  const str2 = arr.join(' ')
  return str2
}

export function toSlug(str) {
  const arr = str.split(' ')

  for (var i = 0; i < arr.length; i++) {
    arr[i] = arr[i].toLowerCase()
  }

  const str2 = arr.join('-')
  return str2
}
