import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

import Button from '../shared/Button'

export const LoginButton = () => {
  const { loginWithRedirect } = useAuth0()

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: '/',
      },
    })
  }

  return <Button className="button__login" clickHandler={handleLogin} label={'Log In'} />
}
