import React from 'react'

import { useAuth0 } from '@auth0/auth0-react'

export const CallbackPage = () => {
  const { error } = useAuth0()

  return (
    <div className="page-layout">
      <div style={{ color: 'white' }} className="page-layout__content">
        {error && error.message}
      </div>
    </div>
  )
}
