import Table from '../../../components/shared/Table.jsx'

import '../../../styles/features/Database/components/GameItemTable.css'

export default function GameItemTable({ title, headers, data }) {
  return (
    <div className="game-item-table">
      <h1>{title}</h1>
      <Table headers={headers} data={data} />
    </div>
  )
}
