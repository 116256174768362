import '../../styles/components/layout/Header.css'

import { Link } from 'react-router-dom'

import { AccessButtons } from '../access-buttons'
import { linkStyle } from '../../components/shared/SharedStyles'

export const Header = () => {
  return (
    <div id="page-header">
      <div id="wordmark">
        <Link style={linkStyle} to="/">
          TheoryForge
        </Link>
      </div>
      <AccessButtons />
    </div>
  )
}
