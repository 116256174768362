import '../../../styles/features/Database/components/ItemDetailDialog.css'

import React from 'react'

const ItemDetailTable = ({ itemStats }) => {
  const tableData = []

  for (const [key, value] of Object.entries(itemStats)) {
    tableData.push(
      <tr key={key}>
        <td className="item-detail-table-stat-name">{key}</td>
        <td>{value}</td>
      </tr>
    )
  }

  return tableData
}

export default function ItemDetailDialog({ itemDetails }) {
  return (
    <div className="item-detail-section">
      <h2>{itemDetails.title}</h2>
      <img src={itemDetails.imageUrl} alt={itemDetails.itemStats.Name}></img>
      <table className="item-detail-dialog-table">
        <tbody>
          <ItemDetailTable itemStats={itemDetails.itemStats} />
          {/*' '*/}
        </tbody>
        {/*' '*/}
      </table>
    </div>
  )
}
