import '../../styles/components/shared/Button.css'

import React from 'react'

export default function Button({ className, label, clickHandler }) {
  return (
    <button className={'base-btn ' + className} onClick={clickHandler}>
      {label}
    </button>
  )
}
