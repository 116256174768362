import { useQuery, gql } from '@apollo/client'

const GET_WEAPON_DETAIL = gql`
  query getWeaponDetails($name: String!) {
    weapon(name: $name) {
      name
      wClass
      tier
      ammoType
      magSize
      fireRate
      runningSpeed
      critChance
      critDamage
      wave
      pierce
      explosionRangeAtkReductionRatio
      atkReductionStartDistance
      penetration
      burst
      shellCapacity
      imageUrl
    }
  }
`

export function useWeaponDetail(weaponName) {
  /* Fetch Weapon Data from TheoryForge API */
  const { loading, error, data } = useQuery(GET_WEAPON_DETAIL, {
    variables: {
      name: weaponName,
    },
  })

  return {
    data,
    error,
    loading,
  }
}
