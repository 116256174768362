export const NAV_SCHEMA = [
  // {
  //   headerSpec: {
  //     label: 'Build Planner',
  //     slug: 'build-planner',
  //   },
  //   itemSpecs: [],
  // },
  {
    headerSpec: {
      label: 'Database',
      slug: 'database',
    },
    itemSpecs: [
      {
        label: 'Launcher',
        slug: 'launcher',
      },
      {
        label: 'Sniper Rifle',
        slug: 'sniper-rifle',
      },
      {
        label: 'Scout Rifle',
        slug: 'scout-rifle',
      },
      {
        label: 'Tactical Rifle',
        slug: 'tactical-rifle',
      },
      {
        label: 'Submachine Gun',
        slug: 'submachine-gun',
      },
      {
        label: 'Machine Gun',
        slug: 'machine-gun',
      },
      {
        label: 'Shotgun',
        slug: 'shotgun',
      },
      {
        label: 'Hand Cannon',
        slug: 'hand-cannon',
      },
      {
        label: 'Hand Gun',
        slug: 'hand-gun',
      },
      {
        label: 'Assault Rifle',
        slug: 'assault-rifle',
      },
      {
        label: 'Beam Rifle',
        slug: 'beam-rifle',
      },
    ],
  },
]
