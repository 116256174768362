import { useQuery, gql } from '@apollo/client'

const GET_WEAPON_TABLE_ROWS = gql`
  query getWeaponsByClass($weaponClass: String) {
    allWeapons(weaponClass: $weaponClass) {
      edges {
        node {
          name
          wClass
          tier
        }
      }
    }
  }
`

export function useWeaponTableRows(weaponClass) {
  /* Fetch Weapon Data from TheoryForge API */
  const { loading, error, data } = useQuery(GET_WEAPON_TABLE_ROWS, {
    variables: {
      weaponClass: weaponClass,
    },
  })

  return {
    data,
    error,
    loading,
  }
}
