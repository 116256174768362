import '../../styles/components/shared/Table.css'

import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'
import { Link } from 'react-router-dom'

import EditIcon from './icons/Edit'
import { linkStyle } from '../../components/shared/SharedStyles'
import { toSlug } from '../../utils/strings'
import { getRandomInt } from '../../utils/random'

export default function Table({ headers, data }) {
  const { isAuthenticated } = useAuth0()

  function createRow(rowData, slug) {
    const tableData = []
    var newCell = undefined

    for (const [index, value] of Object.values(rowData).entries()) {
      if (index === 0) {
        newCell = (
          <td className="first-column" key={`${slug}-${getRandomInt(100)}`}>
            <Link
              to={`/database/weapon/${slug}`}
              key={`${slug}-${getRandomInt(100)}`}
              style={linkStyle}
            >
              {value}
            </Link>
          </td>
        )
      } else {
        newCell = (
          <td key={`${slug}-${getRandomInt(100)}`}>
            <Link
              to={`/database/weapon/${slug}`}
              key={`${slug}-${getRandomInt(100)}`}
              style={linkStyle}
            >
              {value}
            </Link>
          </td>
        )
      }
      tableData.push(newCell)
    }

    if (isAuthenticated) {
      tableData.push(
        <td key={`${slug}-${getRandomInt(100)}`}>
          <Link
            to={`/database/weapon/${slug}/edit`}
            key={`${slug}-${getRandomInt(100)}`}
            style={linkStyle}
          >
            <EditIcon />
          </Link>
        </td>
      )
    }
    return tableData
  }

  return (
    <>
      <table className="tf-table">
        <tbody>
          {/* Table Header */}
          <tr>
            {headers.map((header, index) => {
              if (index === 0) {
                return (
                  <th className="first-column" key={`header-${getRandomInt(100)}`}>
                    {header}
                  </th>
                )
              }
              return <th key={`header-${getRandomInt(100)}`}>{header}</th>
            })}
            {isAuthenticated && <th key={`header-${getRandomInt(100)}`}></th>}
          </tr>
          {/* Table Body */}
          {data.map((rowData, key) => {
            const slug = toSlug(rowData.name)
            return <tr key={key}>{createRow(rowData, slug)}</tr>
          })}
        </tbody>
      </table>
    </>
  )
}
