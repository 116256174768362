import '../../../styles/features/Database/pages/WeaponDetail.css'

import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import { useWeaponDetail } from '../hooks/useWeaponDetail'

import { fromSlug } from '../../../utils/strings'
import ItemDetailDialog from '../components/ItemDetailDialog'

export default function WeaponDetail() {
  const [weaponDetails, setWeaponDetails] = useState({})

  const params = useParams()
  var weaponNameParam = params.weaponName
  weaponNameParam = weaponNameParam.replace('-', ' ')

  const weaponName = fromSlug(weaponNameParam)

  const { data, error, loading } = useWeaponDetail(weaponName)

  useEffect(() => {
    const { weapon } = data || {}

    const weaponStats = {
      Name: weapon?.name,
      Class: weapon?.wClass,
      Tier: weapon?.tier,
      'Ammo Type': weapon?.ammoType,
      'Fire Rate': weapon?.fireRate,
      RunningSpeed: weapon?.runningSpeed,
      'Critical Hit Chance': weapon?.critChance,
      'Critical Hit Damage': weapon?.critDamage,
      Wave: weapon?.wave || 'N/A',
      Pierce: weapon?.pierce || 'N/A',
      'Explosion Range ATK Reduction Ratio (x)':
        weapon?.explosionRangeAtkReductionRatio || 'N/A',
      'ATK Reduction Start Distance': weapon?.atkReductionStartDistance || 'N/A',
      Penetration: weapon?.penetration || 'N/A',
      Burst: weapon?.burst || 'N/A',
      'Shell Capacity': weapon?.shellCapacity || 'N/A',
    }

    setWeaponDetails({
      title: 'Weapon Details',
      itemStats: weaponStats,
      imageUrl: weapon?.imageUrl || '',
    })
  }, [data])

  if (loading) return <p>Loading...</p>
  if (error) return <p>Error : {error.message}</p>

  return (
    <div id="weapon-item-details">
      <ItemDetailDialog itemDetails={weaponDetails} />
    </div>
  )
}
