import '../styles/pages/Motd.css'

import { useAuth0 } from '@auth0/auth0-react'

const PUBLIC_MOTD = `
Please use the navigation menu to view data on game items.
`

const AUTHED_MOTD = `
As a site contributor
you have the ability to add and edit game items.

Please navigate to an item category and begin
adding or updating items you have found in the game.

Thank you for your contributions!
`

export const Motd = () => {
  const { isAuthenticated } = useAuth0()
  return (
    <div id="motd">
      <div id="motd-text">
        <h1>Welcome to TheoryForge!</h1>
        <p>
          {!isAuthenticated && PUBLIC_MOTD}
          {isAuthenticated && AUTHED_MOTD}
        </p>
      </div>
    </div>
  )
}
