import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'

import { Auth0ProviderWithNavigate } from './auth0-provider-with-navigate'
import { AuthenticationGuard } from './components/authentication-guard'

import { CallbackPage } from './routes/Callback'
import { Layout } from './routes/Layout'
import { Motd } from './routes/Motd'

import WeaponsPage from './features/Database/pages/Weapons'
import WeaponDetail from './features/Database/pages/WeaponDetail'
import WeaponEdit from './features/Database/pages/WeaponEdit'

const client = new ApolloClient({
  uri: 'https://tfd.api.theoryforge.io:5000/graphql',
  cache: new InMemoryCache(),
})

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Auth0ProviderWithNavigate>
        <ApolloProvider client={client}>
          <Layout />
        </ApolloProvider>
      </Auth0ProviderWithNavigate>
    ),
    children: [
      {
        index: true,
        element: <Motd />,
      },
      {
        path: 'database',
        children: [
          {
            index: true,
          },
          {
            path: 'weapons/:weaponClass',
            element: <WeaponsPage />,
          },
          {
            path: 'weapon/:weaponName',
            element: <WeaponDetail />,
          },
          {
            path: 'weapon/:weaponName/edit',
            element: <AuthenticationGuard component={WeaponEdit} />,
          },
        ],
      },
      {
        path: 'callback',
        element: <CallbackPage />,
      },
    ],
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
)
