import '../styles/pages/Layout.css'
import '../styles/components/layout/Navigation.css'

import React from 'react'
import { Outlet, NavLink } from 'react-router-dom'

import { Header } from '../components/layout/Header'
// import NavigationPane from '../components/navigation/NavigationPane'
import { NAV_SCHEMA } from '../components/navigation/const/nav-schema'
import { linkStyle } from '../components/shared/SharedStyles'
import { getRandomInt } from '../utils/random'

export const Layout = () => {
  // prettier-ignore
  /* TEMP: Adding the Navigation Elements here b/c it appears that Router components like
  Link and NavLink don't work unless they are direct children of the component that is
  a direct child of BrowserRouter. */

  // prettier-ignore
  /* # --------- START TEMP NAV SCAFFOLDING -----------# */

  function createNavItem(navItemSpec) {
    return (
      <div key={`${navItemSpec.slug}-${getRandomInt(100)})`}>
        <NavLink
          style={linkStyle}
          key={`${navItemSpec.slug}-${getRandomInt(100)}`}
          to={`/database/weapons/${navItemSpec.slug}`}
        >
          <div className="nav-item" key={`${navItemSpec.slug}-${getRandomInt(100)}`}>{navItemSpec.label}</div>
        </NavLink>
      </div>
    )
  }

  function createNavHeader(navHeaderSpec) {
    return (
      <div key={`${navHeaderSpec.label}-${getRandomInt(100)}`}>
        <div key={`${navHeaderSpec.label}-${getRandomInt(100)}`} className="nav-header">
          {navHeaderSpec.label}
        </div>
      </div>
    )
  }

  function createNavSection(sectionSpec) {
    const section = []

    section.push(createNavHeader(sectionSpec.headerSpec))

    sectionSpec.itemSpecs.forEach(function (spec) {
      section.push(createNavItem(spec))
    })

    return <div key={`${sectionSpec.slug}-${getRandomInt(100)}`}>{section}</div>
  }

  function createNavPane(navSchema) {
    const navPane = []
    navSchema.forEach(function (sectionSpec) {
      navPane.push(createNavSection(sectionSpec))
    })

    return <div>{navPane}</div>
  }

  // prettier-ignore
  /* # ---------- END TEMP NAV SCAFFOLDING ------------# */

  return (
    <div className="">
      <main className="app-container">
        <Header />
        <section id="nav">
          <nav>
            {/* <NavigationPane navSchema={NAV_SCHEMA} /> */}

            {/* # --------- START TEMP NAV SCAFFOLDING -----------# */}

            {createNavPane(NAV_SCHEMA)}

            {/* # ---------- END TEMP NAV SCAFFOLDING ------------# */}
          </nav>
        </section>
        <section id="main-pane">
          <Outlet />
        </section>
      </main>
    </div>
  )
}
